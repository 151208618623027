<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div v-frag>
    <div style="min-height: 100vh;">
      <table class="table overflow-x-scroll relative">
        <thead>
          <tr v-if="toggle.filters">
            <td :colspan="fullspan">
              <div class="flex flex-wrap w-full items-center ">
                <FormGroup v-if="queryOn" class="flex-grow w-full mb-6">
                  <div class="flex items-center" style="gap: 20px;">
                    <div class="relative w-full">
                      <Input
                        v-model="query"
                        placeholder="Search"
                        class="text-sm"
                        style="min-width: 300px; max-height: 40px"
                        icon="search"
                        iconset="fas"
                        color="black"
                        @keydown.enter="
                          fetch({
                            ...params,
                            [queryOn]: query
                          })
                        "
                        @input="debouncedSearch"
                      />

                      <Select
                        v-if="searchFields.length"
                        v-model="searchField"
                        :options="searchFields"
                        :placeholder="searchFields[0].label"
                        theme="no-border"
                        class="w-24 ml-2"
                        style="position: absolute !important; right: 0; top: 0;"
                      />
                    </div>

                    <div v-if="filterValues.length">
                      <div class="flex" style="gap: 20px;">
                        <div v-for="(filter, index) in filterValues" :key="'filter-' + index" v-frag>
                          <FormGroup :name="filter.value">
                            <Select
                              ref="searchSelect"
                              :placeholder="filter.placeholder"
                              :options="filter.options"
                              :value="params[filter.value] || null"
                              :searchable="filter.isSearch"
                              :search-multi-select="filter.searchMultiSelect"
                              :filter-value="filter.value"
                              :filter-values="filterValues"
                              :close-on-select="false"
                              class="text-sm"
                              @select="handleSelect(filter, $event)"
                            />
                          </FormGroup>
                        </div>
                        <Button
                          class=" border border-1 border-gray-300 bg-gray-600 text-gray-100 hover:text-gray-600 hover:border-gray-200 hover:bg-gray-200 transition-all"
                          shade="500"
                          style="white-space: nowrap; border-radius: 0.5rem !important; top: 55px; right: 7.5px;"
                          :disabled="!isFilterActive"
                          @click="clearSearch"
                        >
                          Clear Filters
                        </Button>
                      </div>
                    </div>
                  </div>
                </FormGroup>

                <slot
                  v-if="$scopedSlots.filters"
                  v-bind="{
                    fetch,
                    query,
                    params: slotParams
                  }"
                  name="filters"
                />
              </div>
            </td>
          </tr>
          <tr>
            <th v-for="(item, key) in headers" :key="key">
              <Checkbox v-if="item === ' '" @click="$emit('selectAll')" />
              <Icon v-if="item === '#'" name="hashtag" />
              <template v-else>
                {{ item }}
              </template>
            </th>
          </tr>
        </thead>
        <tbody class="text-sm">
          <tr v-if="rows.length < 1" class="table-no-hover">
            <td :colspan="fullspan" class="text-center text-gray-700">
              <div v-if="fetched === 0">
                {{ $t('messages.loading_please_wait') }}
              </div>
              <div v-else-if="fetched === -1" class="mb-36">
                {{ $t('messages.connection_error') }}
              </div>
              <div v-else class="mb-36">
                <div v-if="query !== ''">
                  {{ $t('messages.no_result') }}
                </div>
                <div v-else>
                  {{ $t('messages.no_data') }}
                </div>
              </div>
            </td>
          </tr>
          <tr v-for="(row, rindex) in rows" :key="rindex">
            <td
              v-for="(item, key) in headers"
              :key="`${key}-${rindex}`"
              @click="key != 'selection' ? $emit('row', row) : () => {}"
            >
              <slot
                :name="`col:${key}`"
                v-bind="{
                  item: row,
                  column: row[key],
                  itemIndex: rindex,
                  params: slotParams
                }"
              >
                {{ key === 'phone' ? cleanPhone(row['phone']) : row[key] }}
              </slot>
            </td>
            <td v-if="$scopedSlots.actions" class="table-action-col">
              <slot v-bind="{ item: row, params: slotParams }" name="actions" />
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td :colspan="fullspan - 1">
              <div v-if="from && to && total" class="flex justify-center">
                Page {{ currentPage }}, {{ from }}-{{ to }} of {{ total }}
              </div>
            </td>
          </tr>
          <tr>
            <td :colspan="fullspan - 1" class="w-full">
              <div v-if="pages.length > 1" class="flex justify-center" style="gap: 0.5rem;">
                <button
                  v-if="currentPage >= 8"
                  :class="[
                    'trans-hover',
                    'w-10 h-10',
                    'flex items-center justify-center',
                    'text-center rounded-lg',
                    'border border-solid border-gray-400 hover:border-black',
                    'bg-white',
                    'focus:outline-none'
                  ]"
                  @click="
                    fetch({
                      ...params,
                      page: 1,
                      [queryOn]: query || undefined
                    })
                  "
                >
                  <<
                </button>
                <button
                  v-for="pg in pages"
                  :key="pg"
                  :class="[
                    'trans-hover',
                    'w-10 h-10',
                    'flex items-center justify-center',
                    'text-center rounded-lg',
                    'border border-solid border-gray-400 hover:border-black',
                    'bg-white',
                    'focus:outline-none',
                    {
                      'bg-gray-300': pg === currentPage
                    }
                  ]"
                  @click="
                    fetch({
                      ...params,
                      page: pg,
                      [queryOn]: query || undefined
                    })
                  "
                >
                  {{ pg }}
                </button>
                <button
                  v-if="currentPage <= meta.last_page - 7"
                  :class="[
                    'trans-hover',
                    'w-10 h-10',
                    'flex items-center justify-center',
                    'text-center rounded-lg',
                    'border border-solid border-gray-400 hover:border-black',
                    'bg-white',
                    'focus:outline-none'
                  ]"
                  @click="
                    fetch({
                      ...params,
                      page: meta.last_page,
                      [queryOn]: query || undefined
                    })
                  "
                >
                  >>
                </button>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    src: {
      type: [String, Array],
      default: ''
    },
    initialParams: {
      type: Object,
      default: () => ({})
    },
    imperativeParams: {
      type: Object,
      default: () => ({})
    },
    headers: {
      type: Object,
      default: () => ({ id: '#' })
    },
    body: {
      type: Array,
      default: () => []
    },
    queryOn: {
      type: String,
      default: null
    },
    value: {
      type: Object,
      default: () => ({})
    },
    filterValues: {
      type: Array,
      default: () => []
    },
    searchFields: {
      type: Array,
      default: () => []
    },
    refreshInterval: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      rows: [],
      page: 1,
      meta: null,
      params: this.initialParams,
      query: '',
      fetched: 0,
      toggle: {
        filters: true
      },
      searchField: '',
      debouncedSearch: null,
      isFilterActive: false,
      refreshIntervalId: null
    }
  },
  computed: {
    pages() {
      if (!this.meta || !this.meta.last_page) return []

      const maxPages = 12
      const currentPage = this.meta.current_page
      const totalPages = this.meta.last_page

      let startPage = Math.max(1, currentPage - Math.floor(maxPages / 2))
      let endPage = Math.min(totalPages, currentPage + Math.floor(maxPages / 2))

      if (endPage - startPage < maxPages) {
        if (startPage === 1) {
          endPage = Math.min(totalPages, startPage + maxPages - 1)
        } else if (endPage === totalPages) {
          startPage = Math.max(1, endPage - maxPages + 1)
        }
      }

      return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i)
    },
    slotParams() {
      return {
        page: this.currentPage,
        [this.queryOn]: !this.query && null,
        ...this.params
      }
    },
    fullspan() {
      return Object.values(this.headers).length + 1
    },
    currentPage() {
      return this.meta && this.meta.current_page
    },
    to() {
      return this.meta && this.meta.to
    },
    from() {
      return this.meta && this.meta.from
    },
    total() {
      return this.meta && this.meta.total
    },
    filters() {
      return Object.keys(this.params)
        .filter((x) => {
          if (this.params[x] === null || this.params[x] === undefined) return false
          if (x === 'page') return false
          if (this.imperativeParams[x]) return false
          if (this.initialParams[x]) return false
          return true
        })
        .map((x) => ({
          key: x,
          value: Array.isArray(this.params[x]) ? this.params[x] : [this.params[x]]
        }))
    }
  },
  watch: {
    body(val) {
      if (!this.src) this.rows = this.body
    },
    params(val) {
      const paramLenght = Object.values(this.initialParams).filter((value) => value !== null).length + 1

      const valLenght = Object.values(val).filter((value) => value !== null).length

      if (valLenght > paramLenght) this.isFilterActive = true
      else if (valLenght === paramLenght) this.isFilterActive = false
    }
  },
  created() {
    this.debouncedSearch = this.debounce(this.search, 300)
  },
  async mounted() {
    if (this.src) {
      await this.fetch({ page: 1, ...this.params })

      if (this.refreshInterval) {
        this.refreshIntervalId = setInterval(() => {
          this.refresh()
        }, this.refreshInterval)
      }
    } else {
      this.rows = this.body
      this.fetched = true
    }
  },
  beforeDestroy() {
    clearInterval(this.refreshIntervalId)
  },
  methods: {
    async fetch(params = {}) {
      const response = await this.$axios
        .$get(this.src, {
          params: {
            ...params,
            ...this.imperativeParams,
            ...(this.searchField && { searchBy: this.searchField })
          }
        })
        .then((response) => response)
        .catch(() => {
          this.rows = []
          this.meta = {}
          this.fetched = -1
        })

      if (!response) {
        return
      }

      this.params = params
      this.rows = response.data
      this.meta = response.meta
      this.fetched = 1

      this.$emit('input', {
        rows: this.rows,
        meta: this.meta,
        fetched: this.fetched,
        params: this.params
      })
    },
    debounce(func, delay) {
      let timeoutId
      return function(...args) {
        if (timeoutId) {
          clearTimeout(timeoutId)
        }
        timeoutId = setTimeout(() => {
          func(...args)
        }, delay)
      }
    },
    search() {
      this.fetch({
        ...this.params,
        [this.queryOn]: this.query
      })
    },
    handleSelect(filter, event) {
      this.params = {
        ...this.params,
        [filter.value]:
          event.value !== undefined && event.value !== null
            ? String(event.value)
            : Array.isArray(event) && event.length > 0
            ? event
            : null
      }

      this.fetch(this.params)
    },
    refresh() {
      const { queryOn, query, currentPage } = this
      this.fetch({ [queryOn]: query, page: currentPage })
    },
    removeFilter(filterKey, filterVal) {
      if (Array.isArray(this.params[filterKey])) {
        const index = this.params[filterKey].indexOf(filterVal)
        this.params[filterKey].splice(index, 1)
        this.fetch(this.params)
      } else {
        this.params[filterKey] = null
        this.fetch(this.params)
      }
    },
    closeFiltersOnOutClick(event) {
      if (this.$refs.advancedFilters && !this.$refs.advancedFilters.contains(event.target)) {
        this.closeAdvancedFilters()
      }
    },
    cleanPhone(phone) {
      return phone ? phone.replace(/[|&;$%@"<>(), \-/]/g, '') : ''
    },
    clearSearch() {
      this.query = ''

      if (Array.isArray(this.$refs.searchSelect)) {
        this.$refs.searchSelect.forEach((select) => {
          if (select && typeof select.clearSelectedItems === 'function') {
            select.clearSelectedItems()
          }
        })
      } else if (this.$refs.searchSelect && typeof this.$refs.searchSelect.clearSelectedItems === 'function') {
        this.$refs.searchSelect.clearSelectedItems()
      }

      this.fetch({
        ...this.initialParams,
        page: 1
      })
    }
  }
}
</script>

<style lang="scss">
.table {
  $border-color: theme('colors.gray.300');
  width: 100%;

  &-action-col {
    text-align: right !important;
  }

  th,
  td {
    padding: 8px;
    text-align: left;
    font-weight: 400;

    white-space: nowrap;
  }

  tbody {
    tr {
      background-color: white;
      border-bottom: 1px solid #9f9f9f;
    }

    tr td,
    tr th {
      transition-property: background-color;
      transition-duration: 300ms;
    }

    tr:first-child {
      border-top: 1px solid #9f9f9f;
    }

    td:first-child {
      min-width: unset;
      max-width: unset;
    }

    tr:hover:not(.table-no-hover) td,
    tr:hover:not(.table-no-hover) th {
      background-color: theme('colors.gray.200');
    }
  }
}

.table-filters {
  transform: translateX(100%);
  transition: transform 200ms;

  &.is-active {
    transform: translateX(0);
  }
}
</style>
