export const CONDITION_TYPE = {
  MANUAL: 0,
  AUTOMATED: 1
}

export const CONDITION_COLUMN = {
  TAG: 0,
  PRODUCT_OPTION: 1,
  PRODUCT_OPTION_VALUE: 2,
  PRODUCT_SCORE: 3,
  PRODUCT_TYPE: 4,
  PRODUCT_VARIANT_WEIGHT: 5,
  PRODUCT_DISCOUNT: 6,
  PRODUCT_QUANTITY: 7,
  PRODUCT_VARIANT_QUANTITY: 8,
  PRODUCT_TITLE: 9,
  PRODUCT_VARIANT_PRICE: 10,
  PRODUCT_UNDISCOUNTED_PRICE: 11,
  PRODUCT_CATEGORY: 12,
  PRODUCT_VENDOR: 13,
  PRODUCT_PUBLISHED_AT_DAY: 14,
  PRODUCT_LAST_30_DAYS_SALES: 15,
  PRODUCT_CUSTOM_CATEGORY: 16
}

export const CONDITION_RELATION = {
  EQUAL: 0,
  NOT_EQUAL: 1,
  GREATER: 2,
  LESS: 3,
  START: 4,
  END: 5,
  CONTAINS: 6,
  NOT_CONTAINS: 7,
  YES: 8,
  NO: 9
}

export const COMPONENT_TYPES = {
  STRING: 'string',
  NUMBER: 'number',
  DC_SELECT: 'dc_select'
}

export const CONDITION_COLUMN_TO_RELATION_MAP = {
  [CONDITION_COLUMN.TAG]: [
    CONDITION_RELATION.EQUAL,
    CONDITION_RELATION.NOT_EQUAL,
    CONDITION_RELATION.CONTAINS,
    CONDITION_RELATION.NOT_CONTAINS,
    CONDITION_RELATION.START,
    CONDITION_RELATION.END
  ],
  [CONDITION_COLUMN.PRODUCT_OPTION]: [
    CONDITION_RELATION.EQUAL,
    CONDITION_RELATION.NOT_EQUAL,
    CONDITION_RELATION.CONTAINS,
    CONDITION_RELATION.NOT_CONTAINS,
    CONDITION_RELATION.START,
    CONDITION_RELATION.END
  ],
  [CONDITION_COLUMN.PRODUCT_OPTION_VALUE]: [
    CONDITION_RELATION.EQUAL,
    CONDITION_RELATION.NOT_EQUAL,
    CONDITION_RELATION.CONTAINS,
    CONDITION_RELATION.NOT_CONTAINS,
    CONDITION_RELATION.START,
    CONDITION_RELATION.END
  ],
  [CONDITION_COLUMN.PRODUCT_SCORE]: [
    CONDITION_RELATION.EQUAL,
    CONDITION_RELATION.NOT_EQUAL,
    CONDITION_RELATION.LESS,
    CONDITION_RELATION.GREATER
  ],
  [CONDITION_COLUMN.PRODUCT_TYPE]: [
    CONDITION_RELATION.EQUAL,
    CONDITION_RELATION.NOT_EQUAL,
    CONDITION_RELATION.CONTAINS,
    CONDITION_RELATION.NOT_CONTAINS,
    CONDITION_RELATION.START,
    CONDITION_RELATION.END
  ],
  [CONDITION_COLUMN.PRODUCT_VARIANT_WEIGHT]: [
    CONDITION_RELATION.EQUAL,
    CONDITION_RELATION.NOT_EQUAL,
    CONDITION_RELATION.LESS,
    CONDITION_RELATION.GREATER
  ],
  [CONDITION_COLUMN.PRODUCT_DISCOUNT]: [
    CONDITION_RELATION.EQUAL,
    CONDITION_RELATION.NOT_EQUAL,
    CONDITION_RELATION.CONTAINS,
    CONDITION_RELATION.NOT_CONTAINS,
    CONDITION_RELATION.START,
    CONDITION_RELATION.END
  ],
  [CONDITION_COLUMN.PRODUCT_QUANTITY]: [
    CONDITION_RELATION.EQUAL,
    CONDITION_RELATION.NOT_EQUAL,
    CONDITION_RELATION.LESS,
    CONDITION_RELATION.GREATER
  ],
  [CONDITION_COLUMN.PRODUCT_VARIANT_QUANTITY]: [
    CONDITION_RELATION.EQUAL,
    CONDITION_RELATION.NOT_EQUAL,
    CONDITION_RELATION.LESS,
    CONDITION_RELATION.GREATER
  ],
  [CONDITION_COLUMN.PRODUCT_TITLE]: [
    CONDITION_RELATION.EQUAL,
    CONDITION_RELATION.NOT_EQUAL,
    CONDITION_RELATION.CONTAINS,
    CONDITION_RELATION.NOT_CONTAINS,
    CONDITION_RELATION.START,
    CONDITION_RELATION.END
  ],
  [CONDITION_COLUMN.PRODUCT_VARIANT_PRICE]: [
    CONDITION_RELATION.EQUAL,
    CONDITION_RELATION.NOT_EQUAL,
    CONDITION_RELATION.LESS,
    CONDITION_RELATION.GREATER
  ],
  [CONDITION_COLUMN.PRODUCT_UNDISCOUNTED_PRICE]: [CONDITION_RELATION.LESS, CONDITION_RELATION.GREATER],
  [CONDITION_COLUMN.PRODUCT_CATEGORY]: [
    CONDITION_RELATION.EQUAL,
    CONDITION_RELATION.NOT_EQUAL,
    CONDITION_RELATION.CONTAINS,
    CONDITION_RELATION.NOT_CONTAINS
  ],
  [CONDITION_COLUMN.PRODUCT_VENDOR]: [CONDITION_RELATION.EQUAL],
  [CONDITION_COLUMN.PRODUCT_PUBLISHED_AT_DAY]: [CONDITION_RELATION.GREATER, CONDITION_RELATION.LESS],
  [CONDITION_COLUMN.PRODUCT_LAST_30_DAYS_SALES]: [CONDITION_RELATION.GREATER, CONDITION_RELATION.LESS],
  [CONDITION_COLUMN.PRODUCT_CUSTOM_CATEGORY]: [CONDITION_RELATION.EQUAL]
}

export const CONDITION_COLUMN_TO_COMPONENT_MAP = {
  [CONDITION_COLUMN.TAG]: {
    default: COMPONENT_TYPES.STRING,
    [CONDITION_RELATION.EQUAL]: COMPONENT_TYPES.DC_SELECT,
    [CONDITION_RELATION.NOT_EQUAL]: COMPONENT_TYPES.DC_SELECT
  },
  [CONDITION_COLUMN.PRODUCT_OPTION]: {
    default: COMPONENT_TYPES.STRING,
    [CONDITION_RELATION.EQUAL]: COMPONENT_TYPES.DC_SELECT,
    [CONDITION_RELATION.NOT_EQUAL]: COMPONENT_TYPES.DC_SELECT
  },
  [CONDITION_COLUMN.PRODUCT_OPTION_VALUE]: {
    default: COMPONENT_TYPES.STRING,
    [CONDITION_RELATION.EQUAL]: COMPONENT_TYPES.DC_SELECT,
    [CONDITION_RELATION.NOT_EQUAL]: COMPONENT_TYPES.DC_SELECT
  },
  [CONDITION_COLUMN.PRODUCT_SCORE]: {
    default: COMPONENT_TYPES.NUMBER,
    priceInput: false
  },
  [CONDITION_COLUMN.PRODUCT_TYPE]: {
    default: COMPONENT_TYPES.STRING,
    [CONDITION_RELATION.EQUAL]: COMPONENT_TYPES.DC_SELECT,
    [CONDITION_RELATION.NOT_EQUAL]: COMPONENT_TYPES.DC_SELECT
  },
  [CONDITION_COLUMN.PRODUCT_VARIANT_WEIGHT]: {
    default: COMPONENT_TYPES.NUMBER,
    priceInput: false
  },
  [CONDITION_COLUMN.PRODUCT_DISCOUNT]: {
    default: COMPONENT_TYPES.STRING,
    [CONDITION_RELATION.EQUAL]: COMPONENT_TYPES.DC_SELECT,
    [CONDITION_RELATION.NOT_EQUAL]: COMPONENT_TYPES.DC_SELECT
  },
  [CONDITION_COLUMN.PRODUCT_QUANTITY]: {
    default: COMPONENT_TYPES.NUMBER,
    priceInput: false
  },
  [CONDITION_COLUMN.PRODUCT_VARIANT_QUANTITY]: {
    default: COMPONENT_TYPES.NUMBER,
    priceInput: false
  },
  [CONDITION_COLUMN.PRODUCT_TITLE]: {
    default: COMPONENT_TYPES.STRING
  },
  [CONDITION_COLUMN.PRODUCT_VARIANT_PRICE]: {
    default: COMPONENT_TYPES.NUMBER,
    priceInput: true
  },
  [CONDITION_COLUMN.PRODUCT_UNDISCOUNTED_PRICE]: {
    default: COMPONENT_TYPES.NUMBER
  },
  [CONDITION_COLUMN.PRODUCT_CATEGORY]: {
    default: COMPONENT_TYPES.DC_SELECT,
    [CONDITION_RELATION.EQUAL]: COMPONENT_TYPES.DC_SELECT,
    [CONDITION_RELATION.NOT_EQUAL]: COMPONENT_TYPES.DC_SELECT,
    [CONDITION_RELATION.CONTAINS]: COMPONENT_TYPES.DC_SELECT,
    [CONDITION_RELATION.NOT_CONTAINS]: COMPONENT_TYPES.DC_SELECT
  },
  [CONDITION_COLUMN.PRODUCT_VENDOR]: {
    default: COMPONENT_TYPES.DC_SELECT,
    [CONDITION_RELATION.EQUAL]: COMPONENT_TYPES.DC_SELECT,
    [CONDITION_RELATION.NOT_EQUAL]: COMPONENT_TYPES.DC_SELECT
  },
  [CONDITION_COLUMN.PRODUCT_PUBLISHED_AT_DAY]: {
    default: COMPONENT_TYPES.NUMBER
  },
  [CONDITION_COLUMN.PRODUCT_LAST_30_DAYS_SALES]: {
    default: COMPONENT_TYPES.NUMBER
  },
  [CONDITION_COLUMN.PRODUCT_CUSTOM_CATEGORY]: {
    default: COMPONENT_TYPES.DC_SELECT,
    [CONDITION_RELATION.EQUAL]: COMPONENT_TYPES.DC_SELECT
  }
}

export const PACKAGE_TYPE = {
  BOX: 0,
  ENVELOPE: 1,
  SOFT_PACKAGE: 2
}

export const DISCOUNT_TYPE = {
  PERCENT: 0,
  AMOUNT: 1,
  FREE_SHIPPING: 2,
  BUY_X_PAY_Y: 3,
  BUY_X_FREE_Y: 4,
  GIFT_CARD: 5,
  BUY_X_FREE_PRODUCT: 6
}

export const DISCOUNT_USER_ELIGIBILITY = {
  EVERYONE: null,
  USER: 0
}

export const DISCOUNT_MINIMUM_REQUIREMENT = {
  NONE: null,
  AMOUNT: 0,
  QUANTITY: 1,
  VARIANT_QUANTITY: 2
}

export const DISCOUNT_APPLIES_TO = {
  ORDER: 0,
  COLLECTIONS: 1,
  PRODUCTS: 2,
  PRODUCT_TAGS: 3
}

export const DISCOUNT_STATUS = {
  INACTIVE: 0,
  ACTIVE: 1,
  SCHEDULED: 2,
  EXPIRED: 3,
  FINISHED: 4
}

export const DISCOUNT_STATUSES = [
  DISCOUNT_STATUS.INACTIVE,
  DISCOUNT_STATUS.ACTIVE,
  DISCOUNT_STATUS.SCHEDULED,
  DISCOUNT_STATUS.EXPIRED,
  DISCOUNT_STATUS.FINISHED
]

export const ZONE_COUNTRY_ZIP_TYPE = {
  INCLUDE: 0,
  EXCLUDE: 1
}

export const COLLECTION_TYPE = {
  MANUAL: 0,
  AUTOMATED: 1
}

//                       { label: 'Price', value: 0 },
//                       { label: 'Product Type', value: 1 },
//                       { label: 'Gender', value: 2 },
//                       { label: 'Option', value: 3 },
//                       { label: 'Vendor/Brand', value: 4 }
export const COLLECTION_FILTER_TYPE = {
  PRICE: 0,
  PRODUCT_TYPE: 1,
  GENDER: 2,
  OPTION: 3,
  VENDOR: 4,
  CATEGORY: 5
}

export const PRODUCT_STATUS = {
  DRAFT: 0,
  PUBLISHED: 1
}

// Order Type
// define('ORDER_TYPE_WAITING_INFORMATION', 0);
// define('ORDER_TYPE_WAITING_SHIPPING', 1);
// define('ORDER_TYPE_WAITING_PAYMENT', 2);
// define('ORDER_TYPE_OPEN', 4);
// define('ORDER_TYPE_SHIPPED', 5);
// define('ORDER_TYPE_WAITING_DELIVERY_INFORMATION', 6);
// define('ORDER_TYPE_RETURNED', 7);
// define('ORDER_TYPE_PROCESSING', 8);
// define('ORDER_TYPE_ON_HOLD', 9);
// define('ORDER_TYPE_CANCELLED', 11);
// define('ORDER_TYPE_PARTIALLY_RETURNED', 12);

export const ORDER_TYPES = {
  WAITING_INFORMATION: 0,
  WAITING_SHIPPING: 1,
  WAITING_PAYMENT: 2,
  OPEN: 4,
  SHIPPED: 5,
  WAITING_DELIVERY_INFORMATION: 6,
  RETURNED: 7,
  PROCESSING: 8,
  ON_HOLD: 9,
  CANCELLED: 11,
  PARTIALLY_RETURNED: 12
}

export const ORDER_TYPE_COLORS = [
  'indigo',
  'blue',
  'purple',
  'paid',
  'yellow',
  'green',
  'red',
  'lime',
  'gray',
  'lgreen',
  'brown',
  'pink',
  'gray'
]

// define('ORDER_PAYMENT_METHOD_MANUAL', 0);
// define('ORDER_PAYMENT_METHOD_AMAZON', 1);
// define('ORDER_PAYMENT_METHOD_PAYPAL', 2);
// define('ORDER_PAYMENT_METHOD_KLARNA', 3);
// define('ORDER_PAYMENT_METHOD_SAGEPAY', 4);
// define('ORDER_PAYMENT_METHOD_GOOGLEPAY', 5);
// define('ORDER_PAYMENT_METHOD_JUDOPAY', 6);
// define('ORDER_PAYMENT_METHOD_PAYPAL_EXPRESS', 7);
// define('ORDER_PAYMENT_METHOD_GOOGLEPAY_EXPRESS', 8);
// define('ORDER_PAYMENT_METHOD_APPLEPAY', 9);
// define('ORDER_PAYMENT_METHOD_APPLEPAY_EXPRESS', 10);

export const PAYMENT_METHODS = {
  MANUAL: 0,
  AMAZON: 1,
  PAYPAL: 2,
  KLARNA: 3,
  SAGEPAY: 4,
  GOOGLEPAY: 5,
  JUDOPAY: 6,
  PAYPAL_EXPRESS: 7,
  GOOGLEPAY_EXPRESS: 8,
  APPLEPAY: 9,
  APPLEPAY_EXPRESS: 10,
  GIFT_CARD: 11,
  FREE_PAY: 12
}

export const PAYMENT_METHOD_ICONS = [
  'credit-card',
  'credit-card',
  'credit-card',
  'credit-card',
  'credit-card',
  'credit-card',
  'credit-card',
  'credit-card',
  'credit-card',
  'credit-card',
  'credit-card',
  'gift',
  'credit-cart',
  'terminal'
]

// Meta Key Special Types
export const META_KEY_SPECIAL_TYPES = {
  SIZE_GUIDE: 0,
  MATERIALS: 1,
  CARE_INSTRUCTIONS: 2
}

// Meta Key Types
export const META_KEY_TYPES = {
  STRING: 0,
  NUMBER: 1,
  HTML: 2
}

// Media resized widths
export const MEDIA_WIDTHS = [100, 280, 340, 400, 460, 560, 680, 800, 920]

// Shipping Rate Types
export const SHIPPING_RATE_TYPES = {
  ALL: 0,
  EXPRESS: 1,
  NORMAL: 2
}

/** Currency Change > Country Items **/
export const locales = [
  {
    code: 'en',
    iso: 'en-GB',
    name: 'English',
    symbol: '£',
    currencyCode: 'GBP'
  },
  {
    code: 'de',
    iso: 'de-DE',
    name: 'German',
    symbol: '€',
    currencyCode: 'EUR'
  },
  {
    code: 'fr',
    iso: 'fr-FR',
    name: 'French',
    symbol: '€',
    currencyCode: 'EUR'
  },
  {
    code: 'es',
    iso: 'es-ES',
    name: 'Spanish',
    symbol: '€',
    currencyCode: 'EUR'
  }
]

export const externalLocales = locales.filter((m) => m.code !== 'en')

export const LANGUAGE_CODES = {
  en: 'English',
  de: 'German',
  es: 'Spanish',
  fr: 'French'
}
