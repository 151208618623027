import { PRODUCT_STATUS } from '../utils/enums'

const defaultProduct = {
  seo: {}
}

export const state = () => ({
  product: {
    ...defaultProduct
  },
  locale: 'en',
  isDirty: false
})

export const getters = {
  product(state) {
    return state.product
  },
  locale(state) {
    return state.locale
  },
  isPublished(state) {
    return state.product.id && state.product.id > 0 && state.product.status === PRODUCT_STATUS.PUBLISHED
  },
  isDirty(state) {
    return state.isDirty
  },
  isEdit(state) {
    return state.product.id && state.product.id > 0
  }
}

export const mutations = {
  set(state, item) {
    state.product = item
  },
  setVariant(state, variant) {
    state.product = {
      ...state.product,
      productVariants: [variant]
    }
  },
  setLocale(state, locale) {
    state.locale = locale
  },
  setField(state, { key, value }) {
    const product = { ...state.product }

    // if key has `.` in it, let's split it and update nested object
    if (key.includes('.')) {
      const setNestedField = (obj, keys, value) => {
        const key = keys.shift()
        if (keys.length === 0) {
          obj[key] = value
        } else {
          if (!obj[key]) {
            obj[key] = {}
          }
          setNestedField(obj[key], keys, value)
        }
      }

      const keys = key.split('.')
      setNestedField(product, keys, value)
    } else {
      product[key] = value
    }

    state.product = product
  },
  setDirty(state, isDirty) {
    state.isDirty = isDirty
  }
}

export const actions = {
  setProduct({ commit }, product) {
    commit('set', product)
    commit('setDirty', false)
  },
  setVariant({ commit }, variant) {
    commit('setVariant', variant)
    commit('setDirty', false)
  },
  setDirty({ commit }, isDirty) {
    commit('setDirty', isDirty)
  },
  setLocale({ commit }, locale) {
    commit('setLocale', locale)
  },
  updateProductField({ commit }, { key, value }) {
    // if value is a input event object let's get value from it
    if (value && value.target) {
      value = value.target.value
    }

    commit('setField', { key, value })

    // Mark product as dirty
    commit('setDirty', true)
  },
  resetProduct({ commit }) {
    commit('set', {
      ...defaultProduct
    })
    commit('setDirty', false)
  }
}
